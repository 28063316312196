@if $site == 'norway' {
	/***************** MEDIA QUERIES**************/

	$breakpoints: (
			'phoneport' : 320px,
			'phoneland' : 568px,
			'tabport' : 768px,
			'wide-tab' : 991px,
			'desktop' : 1024px,
			'desktop-basic' : 1200px
	);

	/***************** FONTS *******************/

	$mp_proxima: 'proxima-nova', sans-serif;

	$font-title: Arial, Helvetica, sans-serif;
	$font-body: Arial, Helvetica, sans-serif;

	/***************** GLOBAL *******************/

	$bounds: 1332px;
	$half-bounds: $bounds/2;
	$padding: 0 32px;

	/***************** COLORS *******************/

	// Global Styles
	$background-grey: $light-grey-91;
	$body-text: $charcoal-grey;
	$title-text: $almost-black;

	// Main Colours
	$accent-primary: $nor-orange;
	$accent-secondary: $nor-turqoise;
	$link-primary: $nor-orange;
	$hover-primary: $greyish-brown;

	//Greys
	$dark-grey: $nor-darkgrey;
	$mid-grey: $cool-grey;
	$light-grey: $nor-lightgrey;

	// Success/Error
	$success-color: $corp-apple;
	$error-color: $venetian-red;
	$warn-color: $dull-orange;

	// Section Colours

	//Header
	$header: (
			logoBg : transparent,
			navIconFill : $white
	);

	$footer: (
			primaryBg : $nor-darkgrey,
			secondaryBg : $nor-darkestgrey,
			title : $white,
			text : $white,
			accent : $nor-turqoise
	);

	// Default hedaer style
	$a_background: transparent;
	$a_text: $dark;
	$a_accent: $nor-orange;
	$a_other: transparent;

	// Detail header style
	$b_background: $nor-lightgrey;
	$b_text: $body-text;
	$b_accent: $nor-orange;
	$b_other: transparent;

	//Sidebar widget Style
	$side_background: $nor-darkgrey;
	$side_text: $corp-white;
	$side_background_b: $nor-lightgrey;
	$side_text_b: $corp-white;
	$side_border: transparent;
	$side-icons: $silver;

	/****************************/
	/*  	    IMAGES          */
	/****************************/

	// Banner
	$banner-bg-img: "";
	$topsection-bg-img: "";
	$login-bg-img: "../img/corp-login-bg.jpg";
	//$sales-bg-img: "none";//dont do this it resolves to url("none") which will 404
	$sales-bg-img: "";

	/****************************/
	/* 	      COMPONENTS        */
	/****************************/

	// BUTTONS
	// btn_a
	$a_btn-border: $nor-orange;
	$a_btn-text: $white;
	$a_btn-background: $nor-orange;

	$a_btn-hover-border: $nor-orange;
	$a_btn-hover-text: $white;
	$a_btn-hover-background: $nor-darkgrey;

	// btn_b
	$b_btn-border: $warm-grey;
	$b_btn-text: $almost-black;
	$b_btn-background: transparent;

	$b_btn-hover-border: $dark;
	$b_btn-hover-text: $white;
	$b_btn-hover-background: $dark;

	// btn_c
	$c_btn-border: $bright-red;
	$c_btn-text: $white;
	$c_btn-background: $bright-red;

	$c_btn-hover-border: $dull-red;
	$c_btn-hover-text: $a_btn-hover-text;
	$c_btn-hover-background: $dull-red;

	// btn_d
	$d_btn-border: $nor-orange;
	$d_btn-text: $nor-orange;
	$d_btn-background: transparent;

	$d_btn-hover-border: $nor-orange;
	$d_btn-hover-text: $a_btn-hover-text;
	$d_btn-hover-background: $nor-orange;

	// btn_e
	$e_btn-border: $nor-orange;
	$e_btn-text: $white;
	$e_btn-background: $nor-orange;

	$e_btn-hover-border: $nor-orange;
	$e_btn-hover-text: $nor-orange;
	$e_btn-hover-background: transparent;

    // btn_login
    $login_btn-border: $nor-orange;
    $login_btn-text: $nor-orange;
    $login_btn-background: $white;

    $login_btn-hover-border: $nor-orange;
    $login_btn-hover-text: $nor-orange;
    $login_btn-hover-background: $white;

	// FAQ
	$faq-heading-color: $body-text;
	$faq-plusminus-color: $body-text;
	$faq-bordercolor: $light-grey;
	$faq-header-bg_open: rgba($faq-bordercolor, .3);
	$faq-trigger-color: $body-text;
	$faq-trigger-bg: $corp-silver;
	$faq-trigger-bg_open: $charcoal-grey;
	$faq-trigger-color_open: $white;

	// Tabs
	$tab-heading-color: $body-text;
	$tab-heading-color_active: $body-text;
	$tab-heading-bg: $white;
	$tab-heading-bg_active: $white;

	//Main Tabs Block (Homepage & Dashboard)  - DEPRECATED
	$mainTabs: (
			tabTopBg : $light-grey,
			tabTriggerBg : $petrol,
			tabTriggerBgActive : $petrol,
			tabTriggerBorder : $accent-secondary,
			tabTriggerSpacer : $white,
			tabTriggerIconBg : $petrol,
			tabTriggerIconBgHov : $accent-primary,
			tabTriggerIconColor : $white,
			inactive : #CCCCCC,
			done : #93BE30
	);

	// HEADERFOOTER
	$headerFooter: (
			bg-light : $nor-darkgrey,
			bg-dark : $nor-darkestgrey,
			bg-accent : $accent-secondary,
			colour : $iron,
			hover : $a_accent
	);

	// Location Tabs
	$locationTabs: (
			btnBg : $white,
			btnText : $warm-grey,
			btnBgActive : $white,
			btnTextActive : $almost-black,
			sectionBorder : $silver-three,
			sectionBg : $side_background
	);

	// HEADBAND - DEPRECATED
	$headband: (
			background : $dark,
			text : $white,
			hover : $a_accent
	);

	$datatables: (
			thBg : $nor-darkgrey,
			thText : $white
	);

	$coverTable: (
			thBg : $charcoal-grey,
			thText : $white,
			oddRowBg : rgba($corp-silver, .4),
			evenRowBg : rgba($corp-silver, .8)
	);

	// pushNav - This is out dated and probably no-longer used. Can defo go for corporate skin
	$pushNav: (
			background : $nor-darkgrey,
			text : $white,
			hover : $accent-secondary,
			border : $warm-grey-two
	);

	$offCanvasNav: (
			background : $nor-darkgrey,
			text : $iron,
			header : $white,
			iconsOff : $purplish-grey,
			iconsOn : $white,
			hover : $cinder,
			border : $warm-grey,
			accent : $accent-secondary
	);

	/****************************/
	/* 	      THEMES            */
	/****************************/
	//Content types
	$newstype_news: $cerulean;
	$newstype_event: $sick-green;
	$newstype_guidance: $dull-orange;
	$newstype_cpd: $link-primary;
	$newstype_proposalform: $accent-primary;

	$newstype-colors: (
			news: $cerulean,
			event: $sick-green,
			guidance: $dull-orange,
			cpd: $link-primary,
			proposalform: $accent-primary
	);

	$news-download: $light-burgundy;

	// Products and s4rvices
	$insurance_product: $accent-primary;
	$insurance_service: $link-primary;

	//Banner styles
	$banner-text: $nor-darkgrey;
	$banner-strapline: $accent-primary;

	/****************************/
	/* 	      LAYOUTS           */
	/****************************/

	$login: (
			background : $dark,
			btnBg : $a_btn-background,
			btnBorder : $a_btn-border,
			btnText : $white,
			btnReset : $accent-primary
	);

	$homepage: (
			main-content-bg : $nor-lightgrey,
			tabTopBg : $light-grey
	);

	/********************************************/
	// DASHBOARD
	/********************************************/

	//DASHBOARD VARIABLES
	$policyActionStates: (
			warning : #d0021b,
			needsaction : #ff9933,
			inprogress : #198fc2,
			inactive : #67868e,
			done : #90ce51
	);

	$policyStatuses: (
			status-not-started : map-get($policyActionStates, warning),
			status-open : map-get($policyActionStates, inprogress),
			status-started : map-get($policyActionStates, inprogress),
			status-locked : map-get($policyActionStates, warning),
			status-idle : map-get($policyActionStates, inactive),
			status-inactive : map-get($policyActionStates, needsaction),
			status-returned : map-get($policyActionStates, warning),
			status-complete : map-get($policyActionStates, done),
			status-retracted : map-get($policyActionStates, warning),
			status-submitted : map-get($policyActionStates, inprogress),
			status-not-renewed : map-get($policyActionStates, warning),
			status-unpaid : map-get($policyActionStates, warning)
	);

}

