
div[data-repeatableregionident] {

    & > fieldset {
        .tabCounter {
            right: 50%;
            font: normal 700 12px/32px "Roboto Condensed",sans-serif;
            text-align: center;
            color: $white;
            background: $dusty-gray2;
            z-index: 2;
        }
    }

    &:not([data-layout-style=rows]) {

        @include media('>=tabport') {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            margin: 0 -33px -35px;
            padding-top: 15px;

            & > fieldset,
            & > div {
                position: relative;
                /* flex: 1 1 30%;
                min-width: calc(33.33% - 66px);
                max-width: calc(33.33% - 66px); */
                flex: 1 1 50%;
                min-width: calc(50% - 66px);
                max-width: calc(50% - 66px);
                margin: 0 33px 40px!important;
                box-sizing: border-box;
            }

            & > fieldset {
                padding-top: 30px;

                &::before,
                &::after {
                    display: block;
                    content: "";
                    width: 100%;
                    height: 2px;
                    background: $dusty-gray2;
                }
                &::before {
                    margin-bottom: 30px;
                }
                &::after {
                    margin-top: 50px;
                }

                .rowbuttonwrapper {
                    position: static;
                }

                span.button.deleterow,
                .tabCounter {
                    position: absolute;
                    display: block;
                    top: 0;
                    width: 32px;
                    height: 32px;
                }
                span.button.deleterow {
                    left: 50%;
                    padding: 6px 8px;
                    z-index: 3;
                    min-height:0;
                }

                .jf-row {
                    max-width: none;
                }

                .jf-input-type-checkbox {
                    display: block;
                    margin: 14px 0;

                    & > label {
                        min-width: 150px;
                    }
                }
            }
        }

        @include media('>=tabport','<1200px') {
            margin: 0 -23px -35px;

            & > fieldset,
            & > div {
                flex: 1 1 45%;
                max-width: calc(50% - 46px);
                margin: 0 23px 40px!important;
            }
        }
    }

    @include media('<tabport') {
        position: relative;
        display: block;
        margin: 0 0 -30px;
        padding-top: 15px;

        & > fieldset,
        & > div {
            position: relative;
			display: block;
            flex: none;
            max-width: none;
            margin: 0 0 40px;
            box-sizing: border-box;
        }

        & > fieldset {
            padding-top: 30px;

            &::before {
                display: block;
                content: "";
                width: 100%;
                height: 2px;
                margin-bottom: 30px;
                background: $dusty-gray2;
            }

            .rowbuttonwrapper {
                position: static;
            }

            span.button.deleterow,
            .tabCounter {
                position: absolute;
                display: block;
                top: 0;
                width: 32px;
                height: 32px;
            }
            span.button.deleterow {
                left: 50%;
                padding: 6px 8px;
                z-index: 3;
            }

            .jf-row {
                max-width: none;

                &.jf-input-type-checkbox {
                    display: block;
                }
            }
        }

        .jf-row {
            .locktonContactActionsWrapper {
                top: auto;
                bottom: 0;
                transform: none;
            }
        }
    }
}

/* v2 220916 display each repeated repeatable region in a row */

fieldset {
	&[data-layout-style=rows] {
        margin: 5px 0;
        transition: all .25s;
		display: flex;
		align-items: flex-end;

        @include media('>=tabport') {
            .jf-input-type-repeatable & {
                margin-top: 10px;
                margin-bottom: 10px;
				/* 20210219 - MB switched to use flex-box */
				//display: flex;
				//align-items: flex-end;
                //display:table;
            }

            .jf-row {
				/* 20210219 - MB switched to use flex-box
				    20211021 - MG Flex breaks LSOS, so putting table back until MB is back for me to work this out with
				 */
                display: table-cell !important;
				//flex-grow: 1;
                max-width: none;
                //width: 12%;
                margin: 0;
                padding: 0;
                vertical-align: bottom;

                // Different width depending on number of fields per row - n values out due to delete button and a hidden input.
                // 1 Field
                &:first-child:nth-last-child(4),
                &:first-child:nth-last-child(4) ~ .jf-row {
                    width: 100%;
                    &.jf-input-type-text-200{
                        min-width: 200px
                    }
                }
                // 2 Fields
                &:first-child:nth-last-child(5),
                &:first-child:nth-last-child(5) ~ .jf-row {
                    width:48.25%
                }
                // 3 Fields
                &:first-child:nth-last-child(6),
                &:first-child:nth-last-child(6) ~ .jf-row {
                    width:32.17%
                }
                // 4 Fields
                &:first-child:nth-last-child(7),
                &:first-child:nth-last-child(7) ~ .jf-row {
                    width:24.125%
                }
                // 5 Fields
                &:first-child:nth-last-child(8),
                &:first-child:nth-last-child(8) ~ .jf-row {
                    width:19.3%
                }
                // 6 Fields
                &:first-child:nth-last-child(9),
                &:first-child:nth-last-child(9) ~ .jf-row {
                    width:50px;
                    flex-grow:1;
                }
                // 7 Fields
                &:first-child:nth-last-child(10),
                &:first-child:nth-last-child(10) ~ .jf-row {
                    width:13.8%
                }


                label {
					/*min-width: 100%;
				   max-width: 1px; 030117 hack to stop the labels from making the columns misalign */
                    vertical-align: bottom;
					overflow-wrap: break-word;
					overflow-wrap: anywhere;

                    &:not(.error) {
                        display: block;
                        padding: 0 0 8px;
                        font-size: $th-fs;
                        font-weight: $th-fw;
                        text-transform: uppercase;
                    }

                    dfn {
                        &::after {
                            position: absolute;
                        }
                    }

                    & + textarea {
                        height: 36px;
                    }

                    & + .fileuploader {
                        min-width: 200px;
                    }
                }

                input,
                select {
                    //background-color: $el-bg-req;
                }

                .locktonContactActionsWrapper {
                    top: auto;
                    bottom: 0;
                    transform: none;
                }

                &.jf-contact-row{
                    max-width:220px;
                }

                & + .jf-row  {
                    padding-left: 10px;

                    label.error {
                        left: 10px;
                    }

                    /* (not needed when using flexbox here)
                        MG: back to table for a bit
                    */
                    input,
                    select,
                    .fileuploader {
                        div:not([data-repeatableregionident="locktonPf_tableform_claim"]) > & {
                            width: 100%;
                            margin-left: 0;
                            //box-shadow: none !important;
                        }
                    }
                }

                &.jf-input-type-select {
                    select {
                        min-width: 205px;
                    }
                }

                &.rowbuttonwrapper {
                    min-width: 44px !important;
                    max-width: 44px !important;
                    width: 0.1%;
                    padding-left: 8px;
                }

                & > .collection {
                    min-width: 85px;
                    width: 100%;
                    margin: 0;
                    vertical-align: bottom !important;
					min-height: 37px;
                }

                .chosen-container {
                    /* (not needed when flex) min-width: 100%; */
                    vertical-align: bottom;
                }

                .chosen-single {
                    /* unify the width of chosen dropdowns within a linked form
                    width: 150px;
                    */
                }

                input[type="date"] {
                    padding-right: 6px;
                }
            }

            .tabCounter {
                display: none !important;
            }

            & + fieldset[data-layout-style=rows] .jf-row > label:not(.error) {
				/* display: none; */
				/* if we make this visibly hidden in a way that still affects layout then the
				columns will no longer have uneven widths on top row vs others... */
				height: 0;
				overflow: hidden;
				padding: 0;
				visibility: hidden;
				opacity: 0;
            }

            & ~ .rowbuttonwrapper.jf-row {
                display: inline-block;
                width: 100px;
                margin-top: 7px;
                margin-left: 0;
                text-align: left;
            }

            /* limit the width of different fields */

            .jf-input-type-checkbox {
                width: 0.1%;
				margin: 0!important;
                & > label:not(.error) {
                    padding-right: 7px;
                }
            }

            .jf-input-type-number {
                width: 6%;
                min-width:5.1em;
                & > label:not(.error) {
                    width: 70px;
                }
            }

            .jf-input-type-file {
                &:first-child {
                    width: auto;
                }
            }
        }

        @include media('>=tabport','<1200px') {
            .jf-row {
                &.jf-input-type-select {
                    select {
                        min-width: 80px;
                    }
                }
                &.jf-input-type-date {
                    max-width: 145px;
                    width: 1%;
                    min-width: 4.5em;
                }
                dfn {
                    &::before,
                    &::after,
                    svg {
                        display: none !important;
                    }
                }
                & + .jf-row  {
                    input,
                    select,
                    .fileuploader {
                        div:not([data-repeatableregionident="locktonPf_tableform_claim"]) > & {
                            border-left-width: 1px;

                           /* &:required {
                                border-left-width: 2px;
                            }*/
                        }
                    }
                }
            }
        }

        //sass thinks this is invalid syntax (it lies)
        .jf-row:has(> label.overflowWrapNormal) {
            width: auto!important;
        }
        .overflowWrapNormal {
            overflow-wrap: normal!important; 
        }
        
        // This is a bit hacky - but there to show enough characters for a year e.g. "2000"
        .jf-row:has(> label.min-width-four-chars) {
            min-width:84px; 
        }
    }
}

// Buttons

.rowbuttonwrapper {

    &.jf-row {
        text-align: right;
        margin: 0;
    }

    span.button {
        @extend .jf-btn;  // buttons.scss
        /* color: $btn-color; */
        max-width: 100%;

        &.deleterow{
            display: block;
            width: 36px;
            background: $white;
            border: 1px solid $dusty-gray2;

            svg {
                display: block;
                fill: $dusty-gray2;
            }

            &:hover {
                background: $dusty-gray2;

                svg {
                    fill: $white;
                }
            }
        }

        @at-root .pf-admin &.deleterow{
            display: block;
            width: 36px;
            background: $white;
            border: 1px solid $dusty-gray2;
            padding: 8px 10px;
            cursor: pointer;

            svg {
                display: block;
                fill: $dusty-gray2;
            }

            &:hover {
                background: $dusty-gray2;

                svg {
                    fill: $white;
                }
            }
        }

        &.addrow {
            width: 100px;
            padding-left: 40px;
            padding-right: 20px;
            text-align: left;
            background: $btn-bg;
            cursor: pointer;
            
            svg {
                display: block;
                fill: $btn-color;

                &.icon-jf-add {
                    position: absolute;
                    top: 50%;
                    left: 10px;
                    width: 16px;
                    height: 16px;
                    margin-top: -8px;
					@include media('<tabport') {
						left: auto;
					}
                }
            }

            &:hover {
                background: $btn-bg-hover;
            }
        }
    }
}

// Footer Area

.repeatable-footer-area {
    background: $rfa-bg;
    border: 2px dashed $rfa-border;

    div[data-repeatableregionident] & {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    @include media('>=tabport') {
        div[data-repeatableregionident]:not([data-layout-style=rows]) & {
            min-height: 382px;
            padding: 40px 15px;
            text-align: center;

            span.addrow {
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                flex: none;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: auto;
                height: auto;
                padding: 20px 20px 100px;
                font-size: 18px;
                line-height: 36px;
                font-weight: normal;
                text-align: center;
                color: $text-color;
                background: transparent;

                svg {
                    fill: $btn-bg;
                    transition: all .25s ease-out;

                    &.icon-add,
                    &.icon-jf-add{
                        position: relative;
                        display: block;
                        top: auto;
                        left: auto;
                        width: 120px;
                        height: 120px;
                        margin: 35px auto 0;
                    }
                }

                &:hover {
                    background: $athens-gray;

                    svg {
                        fill: $btn-bg-hover;
                    }
                }
            }

            .csvio {
                position: relative;
                margin-top: 20px;
                z-index: 2;
            }
        }

        div[data-repeatableregionident][data-layout-style=rows] & {
            display: block;
            padding: 6px;

            & > div {
                display: inline-block;
                margin: 6px;
            }
        }
    }

    @include media('<tabport') {
        div[data-repeatableregionident] & {
            min-height: 290px;
            padding: 25px 15px;
            text-align: center;

            span.addrow {
                position: absolute;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                flex: none;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: auto;
                height: auto;
                padding: 20px 20px 100px;
                font-size: 18px;
                line-height: 36px;
                font-weight: normal;
                text-align: center;
                color: $text-color;
                background: transparent;

                svg {
                    fill: $btn-bg;
                    transition: all .25s ease-out;

                    &.icon-add {
                        position: relative;
                        display: block;
                        top: auto;
                        left: auto;
                        width: 120px;
                        height: 120px;
                        margin: 35px auto 0;
                    }
                }

                &:hover {
                    background: $athens-gray;

                    svg {
                        fill: $btn-bg-hover;
                    }
                }
            }

            .csvio {
                position: relative;
                margin-top: 20px;
                z-index: 2;
            }
        }
    }
}

// MISC

*[data-repeatableregionident] {
    .jf-input-type-select {
        select {
            padding-right: 25px;
            appearance: none;
            background: url("/img/source/arrow-down.svg") no-repeat 95% center;
            background-position: calc(100% - 12px) center;

            &[data-type="contact"] {
                padding-right: 50px;
                background-position: calc(100% - 38px) center;
            }
        }
    }
}

/* lockton specific presentation of specific subforms */

div {
    &[data-repeatableregionident="locktonPf_tableform_claim"] {
        fieldset[data-layout-style=rows] {
            .jf-row {
                &.jf-input-type-select select {
                    min-width: 0;
                }
            }
        }
    }

    /*
    &[data-repeatableregionident="locktonPf_form_mergers"] fieldset[data-layout-style=rows] .jf-row:first-of-type {
        width: 50%;
    }
    &[data-repeatableregionident="locktonPf_form_office"] fieldset[data-layout-style=rows] .jf-row {
        &:first-of-type {
            width: 30%;
        }
        input[name=addressPostcode] {
            width: 5em;
        }
    }
    */
    &[data-repeatableregionident="locktonPf_form_regulatoryIssues"] fieldset[data-layout-style=rows] .jf-input-type-textarea {
        width: 90%;
    }
    &[data-repeatableregionident="locktonPf_form_regulatoryIssues"],
    &[data-repeatableregionident="locktonPf_form_regulatoryIssuesMonitoringVisit"],
    &[data-repeatableregionident="locktonPf_form_regulatoryIssuesSraEngagement"] {
        fieldset[data-layout-style=rows] {
            width: 100%;

            .jf-row {
                width: inherit;

                &:first-of-type {
                    min-width: 80px;
                    width: 4em;
                }
                &.jf-input-type-file {
                    max-width: 200px;
                }
            }
        }
    }
}

@include media('>=tabport')  {
    div {
        &[id*=locktonPf_tableform_claim],
        &[id*=locktonPf_tabledocument_claim] {
            &.jf-row {
                margin: 25px 0;
            }
        }
        &[data-repeatableregionident="locktonPf_tabledocument_claim"] fieldset[data-layout-style=rows] .jf-row {
            width: 50%;
        }
        &[data-repeatableregionident="locktonPf_form_foreignLawAdvice"] fieldset[data-layout-style=rows] .jf-input-type-textarea {
            width: 30%;
        }
        &[data-repeatableregionident="locktonPf_form_claims"] fieldset[data-layout-style=rows] .jf-input-type-text {
            width: 70%;
        }
    }
    fieldset > div {
        &[id*=locktonPf_tableform_claim],
        &[id*=locktonPf_tabledocument_claim] {
            & > label {
                margin-bottom: 7px;
                font-size: $intro-fs;
                line-height: $intro-lh;
                font-weight: $intro-fw;
            }
        }
    }
}


// This is to hide on/off buttons that are related to a previous field that is still disabled (in a repeatable block)
/*110316 hide unless prev equals */

.jsonForm {
    .disabled {
        opacity: 0.1;
        pointer-events: none;

        & + .onoffswitch-label {
            opacity: 0.1;
            pointer-events: none;

            .ison,
            .isoff {
                background-color: #666;
            }
        }
    }
}