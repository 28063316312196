// style.scss

/*
 *  Stunnplate style
 *  Version: 2.0
 */
/*
 *  Notes:
 *  Main SCSS file to include entire library followed by custom styles.
 * 
 */

/*-------------------------------------------------------*/
/*- CONFIG                                              -*/
/*-------------------------------------------------------*/
@import 'corporate/config';

/*! Styles built for: #{$site} following the #{$theme} theme */

/*-------------------------------------------------------*/
/*- HELPERS                                             -*/
/*-------------------------------------------------------*/
/*MG helpers have to go first! */
@import 'corporate/helpers/fonts';
@import 'corporate/helpers/mixins';
@import 'corporate/helpers/include-media';
@import 'corporate/helpers/animations';

/*-------------------------------------------------------*/
/*- VARIABLES                                           -*/
/*-------------------------------------------------------*/
// This has to be after include-media for breakpoint variable
@import 'corporate/variables/pallette';
@import 'corporate/variables/default';
//@import 'corporate/variables/solicitors'; // FOLLOWS CORPORATE SO CUSTOMISATION NOT NEEDED
//@import 'corporate/variables/lsos'; // FOLLOWS CORPORATE SO CUSTOMISATION NOT NEEDED
//@import 'corporate/variables/accountants'; // FOLLOWS CORPORATE SO CUSTOMISATION NOT NEEDED
@import 'corporate/variables/norway';
@import 'corporate/variables/mobility';

/*-------------------------------------------------------*/
/*- PLACEHOLDERS                                        -*/
/*-------------------------------------------------------*/
@import 'corporate/helpers/placeholders';


/*-------------------------------------------------------*/
/*- BASE                                                -*/
/*-------------------------------------------------------*/

/* Normalise */
@import 'corporate/base/normalize';

/* Core */
@import 'corporate/base/default';


@import "corporate/base/base"; // Deprecated - need to move styles into other places

/*-------------------------------------------------------*/
/*- COMMON ELEMENTS                                     -*/
/*-------------------------------------------------------*/
@import 'corporate/commondep';

/*-------------------------------------------------------*/
/*- STUNNPLATE LIBRARY MODULES                          -*/
/*-------------------------------------------------------*/
/* Stunn Modules */
@import 'corporate/../../modules/simple-fixed-header/simple-fixed-header';
/*@!import '../../modules/fixedHeader/fixedheader';*/
// @import 'corporate/../../modules/layout/layout';
@import 'corporate/../../modules/layout/flexlayout';
@import 'corporate/../../modules/layout/stickyHeader';
/*@!import '../../modules/nav/nav';*/
@import 'corporate/../../modules/nav/flex-nav';
@import 'corporate/../../modules/tabbed/tabbed';
@import 'corporate/../../modules/modal/modal';
@import 'corporate/../../modules/carousel/carousel';
@import 'corporate/../../modules/accordion/accordion';
/*@!import '../../modules/almostInView/almostInView';*/
@import 'corporate/../../modules/maps/maps';
@import 'corporate/../../modules/flexSlider/flexSlider';
@import 'corporate/../../modules/wizard/wizard';
@import 'corporate/../../modules/policyDurationWidget/policyDurationWidget';
@import 'corporate/../../modules/styledAlert/styledAlert';
@import 'corporate/../../modules/cursorTrackingTooltip/cursorTrackingTooltip';


/* Vendor Modules */
@import "corporate/../../vendor/jquery-ui";
@import "corporate/../../vendor/datatables/datatables";
@import "corporate/../../vendor/chosen/1.4.2/chosen.min";
@import "corporate/../../vendor/select2/4.0.13/css/select2";
@import "corporate/../../vendor/select2/4.0.13/css/select2.theme.stunn";
@import "corporate/../../vendor/ImageSelect/ImageSelect";


/*-------------------------------------------------------*/
/*- COMPONENTS      	                                -*/
/*-------------------------------------------------------*/
@import "corporate/components/alert";
@import "corporate/components/buttons";
@import "corporate/components/back-to-page";
@import "corporate/components/social-icons";
@import "corporate/components/logos";
@import "components/icons";
@import "corporate/components/openCloseIcons";
@import "corporate/components/pagination";
@import "corporate/components/search-form";
@import "corporate/components/titles";
@import "corporate/components/hamburger";
@import "corporate/components/doughnuts";
@import "corporate/components/progressBar";
@import "corporate/components/downloadList";
@import "corporate/components/lists";
@import "corporate/components/flags";
@import "corporate/components/blockquote";
@import "corporate/components/switch";
@import "corporate/components/flex-table";
@import "corporate/components/thirdPartyCheckoutLinks";

/*-------------------------------------------------------*/
/*- WIDGETS         	                                -*/
/*------------------------------------------------------*/
//@import "corporate/widgets/login-form"; // This is only used on Classic skin LSOS and not required
@import "corporate/widgets/accept-decline";
@import "corporate/widgets/quote-form";
@import "corporate/widgets/page-header";
@import "corporate/widgets/resources-filters";
@import "corporate/widgets/resources-popular";
//@import "corporate/widgets/main-tabs";
@import "corporate/widgets/contact-tabs";
@import "corporate/widgets/logo-carousel";
@import "corporate/widgets/location-tabs";
//@import "corporate/widgets/contacts-mobile-only";
//@import "corporate/widgets/sidebar-news-widget";
@import "corporate/widgets/faq";
@import "corporate/widgets/sidebar-speak-to-specialist-widget";
//@import "corporate/widgets/sidebar-obtain-quote-widget";
//@import "corporate/widgets/sidebar-author-widget";
//@import "corporate/widgets/sidebar-downloads-widget";
//@import "corporate/widgets/sidebar-widget_c";
//@import "corporate/widgets/sidebar-widget_d";
@import "corporate/widgets/info-widget";
//@import "corporate/widgets/sidebar-popular-resources";
//@import "corporate/widgets/sidebar-forthcoming-events";
//@import "corporate/widgets/vertical-carousel";
@import "corporate/widgets/banner";
//@import "corporate/widgets/widget-cta";
@import "corporate/widgets/rsa-block";
//@import "corporate/widgets/eventsCalendar";
@import "corporate/widgets/asides/aside";
@import "corporate/widgets/resources-block";
@import "corporate/widgets/affiliations-block";
@import "corporate/widgets/story-facts";
@import "corporate/widgets/tab-control";
@import "corporate/widgets/dashboard-tabs";
@import "corporate/widgets/policy";
@import "corporate/widgets/statusLegend";
@import "corporate/widgets/importContacts";
//webshim datepicker overrides
@import "corporate/widgets/webshim-overides";
@import "corporate/widgets/homeSection";
@import "corporate/widgets/stream-block";
@import "corporate/widgets/two-col-list";
@import "corporate/widgets/slider-mods";
@import "corporate/widgets/getAQuoteOption";
@import "corporate/widgets/modal-pdf";
@import "corporate/widgets/menus/listMenu";
@import "corporate/widgets/modal-pdf";




//Comparison Accordions (These are site specific)
//@import "corporate/widgets/comparison-accordion/comparison-accordion_mobility";
//@import "corporate/widgets/comparison-accordion/comparison-accordion_medica";
//@import "corporate/widgets/comparison-accordion/comparison-accordion_oasis";

// Menus
@import "corporate/widgets/header";
@import "corporate/widgets/notification-bar";
@import "corporate/widgets/menus/main-nav";
@import "corporate/widgets/menus/dropDownMenu";
@import "corporate/widgets/menus/off-canvas";
@import "corporate/widgets/menus/headband";
@import "corporate/widgets/menus/offCanvasControl";
@import "corporate/widgets/menus/offCanvasTabbedMenu";
@import "corporate/widgets/menus/offCanvasForm";
@import "corporate/widgets/menus/site-specific";

// Sales Block
@import "corporate/widgets/sales/sales-medica"; // NOT medica - this needs to be refactored
@import "corporate/widgets/pageBlock"; // Replacement for above (not checked on all sites yet and only in place on stream homepage)

//Admin-Dashboard Shared Styles
@import "corporate/widgets/summary-table"; //admin/dashboard
@import "corporate/widgets/table-flow";//admin/dashboard
@import "corporate/widgets/tables";//admin/dashboard
@import "corporate/widgets/pushNav";//admin/dashboard


//jsonForm
@import '../modules/jsonform/sass/jf-variables';

//Three options
@import "corporate/widgets/three-options";

/*-------------------------------------------------------*/
/*- BASE                                              -  */
/*-------------------------------------------------------*/

@import '../modules/jsonform/sass/base/formNormalise';
@import '../modules/jsonform/sass/base/base';

/*-------------------------------------------------------*/
/*- LAYOUTS                                             -*/
/*-------------------------------------------------------*/

@import '../modules/jsonform/sass/layouts/jsonForm';
@import '../modules/jsonform/sass/layouts/collection';
@import '../modules/jsonform/sass/layouts/row';
@import '../modules/jsonform/sass/layouts/repeatableRegions';
@import '../modules/jsonform/sass/layouts/declaration';
@import '../modules/jsonform/sass/layouts/accordion';
@import '../modules/dialog/dialog';
@import '../modules/quickCreateWizard/quickCreateWizard';


/*-------------------------------------------------------*/
/*- COMPONENTS                                          -*/
/*-------------------------------------------------------*/

@import '../modules/jsonform/sass/components/buttons';
@import '../modules/jsonform/sass/components/loadingSpinner';
@import '../modules/jsonform/sass/components/readOnlyNotice';
@import '../modules/jsonform/sass/components/fileList';
@import '../modules/jsonform/sass/components/fileUploader';
@import '../modules/jsonform/sass/components/inColumns';
@import '../modules/jsonform/sass/components/tables';
@import '../modules/jsonform/sass/components/helpBoxes';
@import '../modules/jsonform/sass/components/forwardQuestion';
@import '../modules/jsonform/sass/components/csvio';
@import '../modules/jsonform/sass/components/closeForAllPanels';
@import '../modules/jsonform/sass/components/inIframe';
@import '../modules/jsonform/sass/components/numberTooltip';
@import '../modules/jsonform/sass/components/notapplicable';
@import '../modules/jsonform/sass/components/legend';

/* Form elements */
@import '../modules/jsonform/sass/components/formElements/common';
@import '../modules/jsonform/sass/components/formElements/input_date';
@import '../modules/jsonform/sass/components/formElements/chosen';
@import '../modules/jsonform/sass/components/formElements/checkbox&radio';
@import '../modules/jsonform/sass/components/formElements/notes';
@import '../modules/jsonform/sass/components/formElements/validation';
@import '../modules/jsonform/sass/components/formElements/contacts&assets';
@import '../modules/jsonform/sass/components/formElements/onOffSwitch';
@import '../modules/jsonform/sass/components/formElements/textarea';
@import '../modules/jsonform/sass/components/formElements/file';
@import '../modules/jsonform/sass/components/fieldset-actions';

/*-------------------------------------------------------*/
/*- WIDGETS                                             -*/
/*-------------------------------------------------------*/

@import '../modules/jsonform/sass/widgets/summaryScreen';
@import '../modules/jsonform/sass/widgets/issuesSummary';
@import '../modules/jsonform/sass/widgets/helpPanel';
@import '../modules/jsonform/sass/widgets/pageControl';
@import '../modules/jsonform/sass/widgets/doughnutChart';

/*-------------------------------------------------------*/
/*- SHAME!!!!                                           -*/
/*-------------------------------------------------------*/

@import '../modules/jsonform/sass/shame';


@import "corporate/widgets/jsonform-overrides";//admin/dashboard

//Three options
@import "corporate/widgets/three-options";

//Dashboard
@import "corporate/widgets/login";
@import "corporate/widgets/acc-contact-details";
@import "corporate/widgets/client-details";
@import "corporate/widgets/notifications";
@import "corporate/components/tooltips";

//Admin
@import "corporate/widgets/overview-block";
@import "corporate/widgets/dtListFilter"; // Datatables List Filter
@import "corporate/widgets/detail-table"; // Detail table - usedin quotes/transaction detail
@import "corporate/widgets/block-stats"; // Client console stats
@import "corporate/widgets/widget-console"; // Client console sidebar
@import "corporate/widgets/client-tabs"; // Nested tabs in console

// Datatables
@import "src/main/sass/corporate/widgets/datatables/datatables"; // Datatables
@import "src/main/sass/corporate/widgets/datatables/documentVisibility"; // Document Visiblity child row
/*-------------------------------------------------------*/
/*- LAYOUTS								                -*/
/*-------------------------------------------------------*/
// @import 'corporate/layouts/default'; // Blank?
@import 'corporate/pages/grid';
@import "corporate/pages/featured-pages";
@import "corporate/pages/default-grid";
//@import "corporate/pages/three-column-grid";
@import "corporate/pages/homepage";
@import "corporate/pages/contact";
@import "corporate/pages/member-detail";
@import "corporate/pages/get-a-quote";
@import "corporate/pages/insurance-index";
@import "corporate/pages/insurance-detail";
@import "corporate/pages/news-detail";
@import "corporate/pages/our-story";
@import "corporate/pages/search-results";
@import "corporate/pages/resources";
@import "corporate/pages/affiliations";
@import "corporate/widgets/footer";
@import "corporate/pages/master-policy";
@import "corporate/pages/partners";

//jsonForm//
//@import "corporate/../../../proposalform/v2016v2/jsonForm.2.0.0/css/style";
//@import "corporate/admin-dashboard/components/pf-form_flex";

//Dashboard
@import "corporate/pages/dashboard";
@import "corporate/pages/dashboard-contact";
@import "corporate/pages/dashboard-account-details";
@import "corporate/pages/dashboard-documents";
@import "corporate/pages/checkout";
@import "corporate/pages/account-switcher";
@import "corporate/pages/checkout/your-order";

//Underwriter
@import "corporate/pages/underwriter";

//PF-Admin
// @import "corporate/pf-admin/admin-dashboard-forms"; // Cleared out and added to jsonform-overrides
@import "corporate/pages/pfadmin";

@import "corporate/pages/admin-home";
@import "corporate/pages/admin-error-occurred";
@import "corporate/pages/admin-pf-warning"; // Also on dashboard
@import "corporate/pages/admin-pf-list";
@import "corporate/pages/admin-pf-view"; // This is actually dashboard
@import "corporate/pages/admin-staff-edit";
@import "corporate/pages/admin-kyc";
@import "corporate/pages/admin-pf-create";
@import "corporate/pages/admin-insurances-edit";
@import "corporate/pages/admin-insurances-create";
@import "corporate/pages/admin-documents";
@import "corporate/pages/admin-clients";
@import "corporate/pages/admin-client-console";
@import "corporate/pages/request-for-quote";
@import "corporate/pages/summary-of-changes";
@import "corporate/pages/admin-assets";
@import "corporate/pages/manual-quote";
@import "corporate/pages/admin-checklist";





/*-------------------------------------------------------*/
/*- LAYOUTS (SITE SPECIFIC)  			                -*/
/*-------------------------------------------------------*/

// Master Policy Guide (LSOS ONLY)
@import 'corporate/components/master-policy/mp-table';
@import 'corporate/components/master-policy/mp-linked-list';
@import 'corporate/components/master-policy/mp-modal';
@import 'corporate/components/master-policy/mp-common';
@import 'corporate/components/master-policy/mp-index';
@import 'corporate/components/master-policy/mp-section_01';
@import 'corporate/components/master-policy/mp-section_02';
@import 'corporate/components/master-policy/mp-section_03';
@import 'corporate/components/master-policy/mp-section_04';
@import 'corporate/components/master-policy/mp-standard-template';
@import 'corporate/components/master-policy/mp-section_06';
@import 'corporate/components/master-policy/mp-section_07';
@import 'corporate/components/master-policy/mp-section_08';
@import 'corporate/components/master-policy/mp-section_09';

/*-------------------------------------------------------*/
/*-     SHAME!!!!!                                      -*/
/*-------------------------------------------------------*/
@import 'corporate/shame';

/*-------------------------------------------------------*/
/*-     PRINT STYLES                                    -*/
/*-------------------------------------------------------*/
@import 'corporate/base/print'; //arguable whether this should go in base

/*-------------------------------------------------------*/
/*-     PRINT STYLES                                    -*/
/*-------------------------------------------------------*/

@import 'corporate/../../vendor/dropzone/dropzone';
@import 'corporate/../../vendor/dropzone/basic';