.select2-container--stunn {
    --bgcolour: #fff;
    --bordercolour: #ccc;
    --textdark: #333;
    --selectedcolour: #1c75bc;
    --borderradius: 0px;
    font-size:13px;
    --height: 2.15rem;
    display:block;
    max-width:400px;
    z-index: 1;
}

.wizardCard .select2-container--stunn{
  max-width:none;
}
.select2-container--stunn .select2-selection--single {
    background-color: var(--bgcolour);
    border: 1px solid var(--bordercolour);
    border-radius: var(--borderradius); 
    height: var(--height);
    vertical-align: middle;
}

.select2-container--stunn .select2-selection--single .select2-selection__rendered {
  color: var(--textdark);
  line-height: var(--height); 
}
.select2-container--stunn .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  /* float: right; */
  font-weight: bold;
  /* position: absolute; */
  /* right: 24px; */
  margin-top: -2px;
  color: #979797;
  margin-right: 8px;
}
.select2-container--stunn .select2-selection--single .select2-selection__placeholder {
  color: #999; 
}
.select2-container--stunn .select2-selection--single .select2-selection__arrow {
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  width: 20px; 
}

.select2-container--stunn .select2-selection--single .select2-selection__arrow b{
  background:none!important;
  /* we're using our own 'icon' (see :before and :after below) */
  background-image: none!important;
  width: 7px!important;
  transform-origin: center;
  transform:rotate(180deg);
  height: 100%;
}
.select2-container--stunn .select2-selection--single .select2-selection__arrow b:before,
.select2-container--stunn .select2-selection--single .select2-selection__arrow b:after{
  content: '';
  display: block;
  background: #979797;
  width: 3px;
  height: 8px;
  position: absolute;
  top: 50%;
  margin-top: -3.6px;
  /*margin-left: -8px;*/
}

.select2-container--stunn .select2-selection--single .select2-selection__arrow b:before{
  transform:rotate(-55deg);
transform-origin: center;
}

.select2-container--stunn .select2-selection--single .select2-selection__arrow b:after{
  transform:rotate(55deg);
  left:4px;
}
  
.select2-container--stunn[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left; 
}

.select2-container--stunn[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto; 
}

.select2-container--stunn.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default; 
}

.select2-container--stunn.select2-container--open .select2-selection--single{
  background: #EEEEEE;
}
.select2-container--stunn.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none; 
}



.select2-container--stunn.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px; 
}
  
.select2-container--stunn .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: var(--borderradius);
  cursor: text; 
}
.select2-container--stunn .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%; 
}
.select2-container--stunn .select2-selection--multiple .select2-selection__rendered li {
  list-style: none; 
}
.select2-container--stunn .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
  padding: 1px; 
}
.select2-container--stunn .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: var(--borderradius);
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px; 
}
.select2-container--stunn .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px; 
}
.select2-container--stunn .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333; 
}
  
.select2-container--stunn[dir="rtl"] .select2-selection--multiple .select2-selection__choice, .select2-container--stunn[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right; 
}
  
.select2-container--stunn[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto; 
}
  
.select2-container--stunn[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto; 
}
  
.select2-container--stunn.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0; 
}
  
.select2-container--stunn.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default; 
}
  
.select2-container--stunn.select2-container--disabled .select2-selection__choice__remove {
  display: none; 
}
  
.select2-container--stunn.select2-container--open.select2-container--above .select2-selection--single, .select2-container--stunn.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0; 
}
  
.select2-container--stunn.select2-container--open.select2-container--below .select2-selection--single, .select2-container--stunn.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; 
}

.select2-container--stunn .select2-search--dropdown .select2-search__field {
  background: url(/img/vendor/chosen/chosen-sprite.png) no-repeat 100% -20px;
  border: 1px solid #aaa; 
}
  
.select2-container--stunn .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield; 
}
  
.select2-container--stunn .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto; 
}
  
.select2-container--stunn .select2-results__option[role=group] {
  padding: 0; 
}
  
.select2-container--stunn .select2-results__option[aria-disabled=true] {
  color: #999; 
}

.select2-container--stunn .select2-results__option[aria-selected=true] {
  background-color: #ddd; 
}
  
.select2-container--stunn .select2-results__option .select2-results__option {
  padding-left: 1em; 
}
.select2-container--stunn .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0; 
}
.select2-container--stunn .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em; 
}
.select2-container--stunn .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em; 
}
.select2-container--stunn .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em; 
}
.select2-container--stunn .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em; 
}
.select2-container--stunn .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em; 
}
  
.select2-container--stunn .select2-results__option--highlighted[aria-selected] {
  background-color: var(--selectedcolour);
  color: white; 
}
  
.select2-container--stunn .select2-results__option--highlighted[aria-selected] .select2-rendered__match {
  background-color: var(--selectedcolour);
}
.select2-container--stunn .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px; 
}

.select2-rendered__match {
  text-decoration: underline;
  background:#FEFFDE
}