.main-nav-wrap {
    flex: 1 1 60%;
    @if ($site == lsos) {
        @include media("<=450px") {
            flex: 1 1;
        }
    }
    z-index: 20;

    .main-nav {
        flex: 1 1 auto;

        justify-content: flex-end;

        & > li {
            position: relative;
            flex: 1 1 auto;
            font-size: 16px;
            line-height: 42px;

            @if ($site == lsos) {
                padding: 31px 5px;
            } @else if ($site == norway) {
                padding: 31px 5px;
                &:last-child {
                    padding-right: 0;
                }
            } @else if ($site == recruitment) {
                padding: 31px 5px;
                &:last-child {
                    padding-right: 0;
                }
            } @else {
                padding: 31px 0;
            }

            text-align: center;


            @include media("<=wide-tab") {
                &:not(.off-canvas-controls){
                    display: none;
                }
            }
            @include media("<=phoneland") {
                padding:8px 0;
            }
            a {
                display: inline-block;

                vertical-align: middle;
                text-decoration: none;
/*                line-height: 16px;*SR */
                line-height: 36px;
                font-size: 14px;
                font-weight: 300;
                color: map_get($headerFooter, colour);
                position: relative;

                @include media("<=wide-tab") {
                    height: auto;

                    font-size: 16px;
                    line-height: 20px;

                    color: map_get($pushNav, text);

                    text-align: left;
                    text-transform: capitalize;
                }
                &:after{
                    display:block;
                    width:100%;
                    height:3px;
                    background:$link-primary;
                    @if ($site == norway) {
                        background:$nor-turqoise;
                    }
                    opacity:0;
                    transition: all 300ms ease;
                    position:absolute;
                    left:0;
                    right:0;
                    top:calc(100% + 30px);
                    content:'';
					pointer-events: none;
                }
                &#pdfdownloadbutton:after{
                    content:none;
                }
                &:hover, &:active {
                    color: $white;
                    &:after{
                        opacity:1;
/*                        top:calc(100% + 10px); SR */
                        top:100%;
                    }
                }
            }
			&:hover > a{
				color: $white;
				&:after{
/*SR					opacity:1;
					top:calc(100% + 10px);*/
				}
			}
            &.on:not(:first-child) > a {
                color: $white;
            }
            &.has-submenu:hover{
                background:$almost-black;
                a + .first-level-submenu{
                    max-height:700px;
/*                    opacity:1;
                    transition:max-height 0s linear 400ms;*/
                }
            }
			&.create.qcwizard {
				/*a .icon-wizard {
					border: 1px solid #222;
					border-radius: 3px;
					vertical-align: middle;
					padding: 1px;
					background: rgba(255,255,255,0.6);
					box-shadow: 0 0 0 1px rgba(255,255,255,0.6);
					height: 28px;
					width: 28px;
				}
				&:hover > a .icon-wizard {
					background: rgba(255,255,255,0.9);
					box-shadow: 0 0 0 1px rgba(255,255,255,0.9);
				}*/
				a .icon-wizard {
					transition: all 300ms ease;
					vertical-align: middle;
					height: 30px;
					width: 30px;
					fill:map_get($headerFooter, colour);
				}
				&:hover > a .icon-wizard {
					fill: $white;
				}
			}
			
        }
		
		
		
		


    }

    #pdfdownloadbutton{
        line-height: 25px;
		&.disabled {
			opacity: 0.25;
			cursor: wait;
		}
    }

    .main-nav li.a4blive a {
        line-height: 24px;
        &:after{
            display:none;
        }
    }
}