// This is for all admin/dashboard jsonForm overrides
.pf-dashboard,
.pf-admin {
    .jsonForm {
        fieldset {
            max-width: 600px;
            //padding: 10px 0;
            //border: 0 none;
        }
        //legend{
        //	font-weight:600;
        //}
        //section h2{
        //    padding:0;
        //}
    }
    //.jsonForm button[type="submit"]{
    //    background-color:#1C75BC;
    //}
   
    //.jsonForm .jf-header-bounds,
    //.jsonForm > section > header > h2,
    //.jsonForm > section > .intro,
    //.jsonForm > section > fieldset,
    //.jsonForm > section > .not-applicable {
    //    padding-right: 0;
    //    padding-left: 0;
    //}
}

.pf-checkout {
    .jsonForm input[type='submit'] {
        font-size: 14px;
        line-height: 17px;

        margin-top: 0;
        padding: 6px 20px;

        color: $a_btn-text!important; //Overriden by top-section link colour/dashboard link colour otherwise;
        border: solid 1px $a_btn-border;
        background-color: $a_btn-background;

        &:hover,
        &:active {
            color: $a_btn-hover-text;
            border: solid 1px $a_btn-hover-border;
            background-color: $a_btn-hover-background;
        }
    }
}
.pf-admin{
    //.jsonForm > section {
    //    padding: 0;
    //}
    //section:last-of-type,
    //.jsonForm > section:last-of-type {
    //    padding: 0;
    //}
    //.jsonForm textarea {
    //    height: 12em;
    //}
    //.jsonForm .chosen-container {
    //    max-width: 100%;
    //}
        // Appears t be used in the login/create and login/edit pages
    //.jsonForm .jf-row.jf-input-type-text#permissions .check-wrap {
    //    display: block;
    //
    //    width: 100%;
    //}
    //.jsonForm .jf-row.jf-input-type-text#permissions input {
    //    clear: left;
    //
    //    width: 30px;
    //}
	
	
	//pf-admin has a list of checkboxes for the client login add/edit permissions.
	// As of 30/07/20 these were unstyled and looked awful. Hence the following.
	.check-wrap {
		display: flex;
		line-height: 1.5em;
		align-items: baseline;

		ul, li{
			list-style: none;
			position:relative;
		}
		
		input[type='checkbox'] {
			margin-right: 10px;
		}
		
		//optional fo checkbox (overrides to inherited focb styling form elsewhere)
		input[type='checkbox'].focbed {
			/* the checkbox is hidden elsewhere but we need rto position this better so that the native validation is actually visible */
			opacity: 0.01;
			width: 1px;
			height: 1px;
			left: 0;
			/*bottom: 0; do not position on Y else the viewport will jump when toggling a fo-checkbox*/
			z-index: inherit;
			+ .focb {
				display: flex;
				align-items: center;
				margin: 0 5px 5px 0;
				flex:1;
				&:before {
					margin-right: 10px;
					border-color: #9b9b9b;
					flex-shrink: 0;
				}
			}
			
			&:required + .focb:before {
				border-color: #f5a623;
			}
			
			&:checked + .focb {
				color: black;
				
				&:before {
					border-color: #90ce51;
				}
			}
			
			//&:read-only + .focb, /*a checkbox cannot be readonly */
			&[readonly] + .focb,
			&[disabled] + .focb,
			&:disabled + .focb{
				opacity: 0.5;
				cursor: not-allowed;
			}
		}
	}
	//wrap the .check-wrap list in this if you want them styled side by side rather than stacked vertically
	.check-wrap-flow {
		display: flex;
		flex-wrap: wrap;
		.check-wrap input[type='checkbox'].focbed+.focb {
			box-shadow: #eee 0 0 0 1px inset;
			padding: 0 10px 0 0!important;
		}
		.check-wrap input[type='checkbox'].focbed:checked+.focb {
			background-color: #eee;
		}
	}



}
#declarationPage #declaration{
padding-top:0;
}


/* 
Oct 2022 copied a subset of professions _jsonform-overrides in order to improve the corporate theme for GFI 
*/

.jsonForm .intro {
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: 0.26px;
    color: #1c1c1b;
}

.jsonForm .intro-content {
    display: block;
}

.jsonForm fieldset > legend {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.17px;
    margin-bottom: 10px;
    max-width: calc(100vw - 60px);
}

.jsonForm .page-header .bounds > .flex-parent .column-title, .jsonForm .page-header .jf-header-bounds > .flex-parent .column-title {
    display: inline-block !important;
    line-height: 0.8em !important;
    margin-bottom: 34px;
    width: fit-content;
}


.jsonForm .not-applicable, .jsonForm .major-prop-not-applicable {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: 0.2px;
    text-align: center;
    display: inline-block;
    margin: 12px 0 25px;
}

body.pf-form:not(.accordionnice) {
    .jsonForm .jf-row,
    .jsonForm .intro-content,
    .jsonForm legend {
        //max-width: min(772px, 100%)!important; //Gulp WRONGLY errors
        max-width: #{"min(772px, 100%)"}; //use string interpolation as a workaround
    }
}

.jsonForm input[type="text"], .jsonForm input[type="email"], .jsonForm input[type="password"], .jsonForm input[type="tel"], .jsonForm input[type="number"], .jsonForm input[type="date"], .jsonForm input[type="month"], .jsonForm input[type="cash"], .jsonForm input[type="percent"], .jsonForm select {
    min-width: 50px;
}

.jsonForm .jf-header-bounds, .jsonForm > section > header > h2, .jsonForm > section > .intro, .jsonForm > section > fieldset {
    padding: 0 32px;
}

.jsonForm > section > .intro-content {
    margin-left: 0;
}

.jsonForm > section > header > h2 {
    padding: 16px 32px;
}

@media (min-width: 767px) {
    .jsonForm .jf-row.jf-input-type-repeatable {
        max-width: calc(100vw - 32px);
        overflow: visible;
        padding: 1px;
        margin-left: 0px;
        box-sizing: border-box;
    }
}

@media (min-width: 767px) {
    fieldset[data-layout-style=rows] .jf-row textarea {
        min-width: 200px;
    }
    fieldset[data-layout-style=rows] .jf-row.jf-input-type-onoffswitch {
        width: 96px !important;
    }
}

.jsonForm .jf-row.notesDiv {
    max-width: inherit;
    transition-property: margin;
    transition-delay: 200ms;
    transition-duration: 100ms;
    position: absolute;
    opacity: 0;
}

.jsonForm .jf-row.notesDiv.isopen {
    margin-top: var(--margin-top);
    transition-delay: 0s;
    position: relative;
    opacity: 1;
}

div[data-layout-style='rows'] {
    display: table;
    width: 100%;
}

@media (max-width: 767px) {
    fieldset[data-layout-style=rows] .jf-row .chosen-container, fieldset[data-layout-style=rows] .jf-row .chosen-container[style] {
        margin-left: 0 !important;
        width: 100% !important;
    }
}

@media (min-width: 768px) {
    fieldset[data-layout-style=rows] .jf-row .chosen-container, fieldset[data-layout-style=rows] .jf-row .chosen-container[style] {
        /*width: clamp(100px,180px,180px)!important;*/
        margin-left: 0 !important;
    }
}

@media (max-width: 767px) {
    .jsonForm .jf-row.jf-input-type-datatable {
        max-width: calc(100vw - 30px) !important;
        overflow-x: scroll;
    }
}


@media (min-width: 768px) {
    fieldset[data-layout-style=rows] .jf-row label {
        overflow-wrap: break-word;
        width:
        auto!important;
    }
}

.jsonForm table:not(.dataTable) thead th {
    vertical-align: bottom;
    padding: 4px 3px;
}

.jsonForm table:not(.dataTable) tr[data-is-total-row="true"] th {
    padding-right: 10px !important;
    border: none;
}

.jsonForm table:not(.dataTable) th > span {
    vertical-align: bottom;
}

.jsonForm .jf-row {
    margin: 0 0 10px 0;
}


.jsonForm input[type="text"],
.jsonForm input[type="email"],
.jsonForm input[type="password"],
.jsonForm input[type="tel"],
.jsonForm input[type="number"],
.jsonForm input[type="date"],
.jsonForm input[type="month"],
.jsonForm input[type="cash"],
.jsonForm input[type="percent"],
.jsonForm select,
.jsonForm textarea,
.jsonForm .chosen-container .chosen-single,
.jsonForm .chosen-container .chosen-choices,
.jsoForm .fileuploader {
    border-width: 1px !important;
    margin-left: 6px !important;
    width: calc(100% - 6px) !important;
}

.jsonForm .chosen-container {
    margin-left: 0 !important;
}

@media (min-width: 768px) {
    fieldset[data-layout-style=rows] .jf-row + .jf-row {
        padding-left: 6px;
    }
}


fieldset[data-layout-style=rows] .jf-row.rowbuttonwrapper {
    min-width: 43px !important;
    max-width: 43px !important;
}

.jsonForm table:not(.dataTable) td {
    padding: 4px 3px;
}

.jsonForm input, .jsonForm textarea, .jsonForm select {
    font-size: 14px !important;
    color: #1c1c1b;
    font-family: inherit;
}

@media (min-width: 768px) {
    fieldset[data-layout-style=rows] .jf-row > .collection {
        flex-wrap: nowrap;
        justify-content: space-between;
        height: 36px;
    }

    fieldset[data-layout-style=rows] .jf-row > .collection > label:not(.error) {
        margin: 0;
        padding: 0;
    }
    .jf-input-type-repeatable fieldset[data-layout-style=rows] {
        margin-bottom: 10px !important;
    }
}

.jsonForm .jf-row label {
    font-size: 13px;
    font-weight: normal;
    line-height: 16px;
}

.jsonForm .jf-row > label:not(.focb):empty {
    display:none
}
//but not in this case
.jsonForm .jf-row>label+input+label:empty {
    display: block;
}


@media (min-width: 768px) {
    div > fieldset[data-layout-style=rows] .jf-row + .jf-row .fileuploader {
        width: calc(100% - 6px) !important;
        margin-left: 6px !important;
    }
}

@media (min-width: 768px) {
    /*fieldset[data-layout-style=rows] .jf-row {
        width: 35%;
    }*/

    fieldset[data-layout-style=rows] .jf-row.rowbuttonwrapper {
        min-width: 41px !important;
        max-width: 41px !important;
    }
    .pf-form .jsonForm {
        min-width: #{"min(773px, 100%)"};
    }
}


@media (max-width: 767px) {
    .pf-form ul.jf-page-control {
        display: none;
    }
    .pf-form .empty-right-column {
        display: none;
    }
}


.pf-form .empty-right-column[style] {
    flex-basis: 25%;
    //max-width: min(300px, 25%);//Gulp WRONGLY errors
    max-width: #{"min(300px, 25%)"}; //use string interpolation as a workaround
    min-width: 0;
}

.jsonForm .jf-row.jf-input-type-datatable table {
    //min-width: min(100%, 500px);//Gulp WRONGLY errors
    min-width: #{"min(100%, 500px)"}; //use string interpolation as a workaround
}

/* for some reason this is globally overidden in getaquoteoption.scss */
.pf-form .alertDialog {
    position: fixed !important;
}

/* equalise the spacing a bit */
.jsonForm .jf-row>.collection>label:not(.error) {
    margin-block: 0;
}
.jsonForm .jf-row>.collection {
    margin-block:0
}
.jsonForm .jf-row {
    margin-bottom: 16px;
}
.jsonForm label {
    padding-block: 8px;
}

.jsonForm section header + .intro {
    margin-top: 32px;
}

.jsonForm fieldset ul {
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5em;
    padding-block: 8px;
}

#forceSubmissionBlock {
    .forceSubmissionMessage {
        display:flex;
        align-items:center;
        justify-content:space-between;
        max-width: inherit;
    }
    .forceSubmissionMessage p {margin:0;}
    .forceSubmissionMessage:after {display:none;}
}

/* Remove the space between fieldsets when they are without legends and thus are only really there for grouping questions to be revealed.
 * This is only supported in modern browsers. */
fieldset + fieldset:not([data-repeatindex]):not(fieldset:has(legend)){
    margin-top:-40px;
}


label.focb .note {
    color: orange;
    margin-inline: 1em;
    display: none;
}
label.focb:hover .note {
    display: inline;
}