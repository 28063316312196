.modal_pdf{

    left: 0;

    .row{
        margin-bottom:8px;
    }
    .icon{
        display:inline-block;
        vertical-align: middle;
        height:20px;
    }
    svg{
        height:12px;
        width:12px;
    }

    &.active{
        .modal-overlay{

            display: flex;
            flex-direction: column;
            height: 100%;

            &:before{
                height: unset;
            }

            .pdf-control-panel{
                width: 100%;
                background-color: #333333;
                box-sizing: border-box;
                box-shadow: 0 5px 5px rgba(39,39,39,0);
                display: flex;
                flex-direction: row;
                padding: 16px;
                -ms-flex-align: start;
                -webkit-align-items: center;
                -ms-flex-line-pack: center;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                column-gap: 20px;

                .control-panel-block{
                    /* -webkit-flex: 1 1 25%;
                    -ms-flex: 1 1 25%;
                    flex: 1 1 25%;
                    text-align: left; */

                    &.page-summary{
                        text-align: center;
                    }

                    @include media("<=tabport") {
                        &:first-child {
                            flex-basis: max-content;
                        }
                    }

                    .doc-name{
                        color: $white;
                    }

                    .page-num-summary{
                        color: #868686;
                        font-size: 14px;
                        font-weight: normal;
                        margin: 0 10px;
                        white-space: nowrap;
                        &:hover{
                            color: #ffffff;
                        }

                        span[id^='prevPage-']{
                            margin-right: 20px;
                            cursor: pointer;
                            -webkit-touch-callout: none;
                            -webkit-user-select: none;
                            -khtml-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                        }

                        span[id^='nextPage-']{
                            margin-left: 20px;
                            cursor: pointer;
                            -webkit-touch-callout: none;
                            -webkit-user-select: none;
                            -khtml-user-select: none;
                            -moz-user-select: none;
                            -ms-user-select: none;
                            user-select: none;
                        }
                    }

                    input[id^='page_input-']{
                        max-width: 32px;
                        width: 100%;
                        background-color: transparent;
                        border: none;
                        border-bottom: 1px solid;
                        color: #868686;
                        text-align: center;
                        &:focus{
                            color: #ffffff;
                        }
                    }

                    /* Chrome, Safari, Edge, Opera */
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    /* Firefox */
                    input[type=number] {
                        -moz-appearance: textfield;
                    }

                    .pdf-preview-icons-block{
                        text-align: right;
                        .pdf-preview-icons {
                            display: inline-block;
                            width: fit-content;
                            padding-right: 16px;
                        }
                    }


                    .modal-close{
                        position: relative;
                        width: 40px;
                        right: unset;
                        top: unset;
                        z-index: unset;
                        color: $white;
                        font-weight: bold;
                    }
                }
            }

            .modal-contentWrap {
                /* width: unset;
                height: unset;
                padding: 0;
                margin-top: 8%;

                @include media("<=tabport") {
                    margin-top: 10%;
                }

                @include media("<=phoneland"){
                    margin-top: 25% !important;
                } */

                /* max-width: fit-content;
                max-height: fit-content;
                width: 100%;
                --a4: 1.41;
                padding: calc(3vw * var(--a4)) 3vw;
                aspect-ratio: 1/var(--a4);
                margin: auto; */

                background-color: #eeeeee;
                margin: auto!important;
                width: auto!important;
                max-width: 100%!important;
                padding: 0!important;
                max-height: none!important;
                border: none;

                min-width:210px;
                min-height:297px;
                background-size: 72px 72px;
                background-position: center;
                background-repeat: no-repeat;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3e%3cmask id='a' width='24' height='24' x='0' y='0' maskUnits='userSpaceOnUse' style='mask-type:alpha'%3e%3cpath fill='%23D9D9D9' d='M0 0h24v24H0z'/%3e%3c/mask%3e%3cg mask='url(%23a)'%3e%3cpath fill='%231C1B1F' fill-opacity='.5' d='M9 12.5h1v-2h1c.283 0 .52-.096.713-.287A.968.968 0 0 0 12 9.5v-1a.967.967 0 0 0-.287-.713A.968.968 0 0 0 11 7.5H9v5Zm1-3v-1h1v1h-1Zm3 3h2c.283 0 .52-.096.713-.287A.968.968 0 0 0 16 11.5v-3a.967.967 0 0 0-.287-.713A.968.968 0 0 0 15 7.5h-2v5Zm1-1v-3h1v3h-1Zm3 1h1v-2h1v-1h-1v-1h1v-1h-2v5ZM8 18c-.55 0-1.02-.196-1.412-.587A1.926 1.926 0 0 1 6 16V4c0-.55.196-1.02.588-1.413A1.926 1.926 0 0 1 8 2h12c.55 0 1.02.196 1.413.587.39.393.587.863.587 1.413v12c0 .55-.196 1.02-.587 1.413A1.926 1.926 0 0 1 20 18H8Zm0-2h12V4H8v12Zm-4 6c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 0 1 2 20V6h2v14h14v2H4Z'/%3e%3c/g%3e%3c/svg%3e");
                animation-name: THROB;
                animation-duration: 2s;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
              
              


                .modal-content{
                    .pdf-preview{
                        canvas[id^='canvas-'] {
                            border: 1px solid black;
                            direction: ltr;
                        }
                    }
                }
            }

            .icon-download {
                left: 50%;
                transform:translateX(-50%);
                fill: $white;
            }

            .icon-pdf-preview-close{
                left: 50%;
                transform:translateX(-50%);
                fill: $white;
            }

            .scale-control span {
                color: #868686;
                display: inline-flex;
                vertical-align: middle;
                padding: 2px;
            }

            .scale-control span:hover {
                color: $white;
            }

            .scale-control svg {
                height: 20px;
                width: 20px;
                fill: currentColor;
            }


        }
    }
}

@keyframes THROB {
    50% {background-size: 48px 48px}
 }